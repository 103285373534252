<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- TODO: this view isn't used in customer-website-html5 , remove it -->
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'account-dashboard', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Account summary</router-link>
                    <!-- <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.realmId } }">Organization</router-link> --> <!-- TODO: missing organization id ... -->
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dashboard</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Payment history</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>
                            <template v-if="isDataReady && Array.isArray(paymentList)">
                                <p class="mb-0 pb-0" v-if="paymentList.length === 0">
                                    No payments in this account
                                </p>
                                <v-simple-table v-if="paymentList.length > 0">
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <!-- <th>Status</th> -->
                                                <th>ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="payment in paymentList" :key="payment.id">
                                                <td>
                                                    {{ formatDate(payment.created_on) }}
                                                </td>
                                                <td>
                                                    {{ formatAmount(payment.currency, payment.amount_csu) }}
                                                </td>
                                                <!-- <td>
                                                    {{ orderStatusDisplay(order.status) }}
                                                </td> -->
                                                <td>
                                                    {{ paymentIdText(payment.id) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    data: () => ({
        paymentList: null,
        isDataReady: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
            palette: (state) => state.palette,
            brand: (state) => state.brand,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    watch: {
        focus() {
            // reload data when user returns to this tab
            this.loadPaymentHistory();
        },
    },
    methods: {
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatAmount(currency, amountCSU) {
            return fromCurrencyAmountCSU(currency, amountCSU).toStringWithCurrencySymbol();
        },
        paymentIdText(paymentId) {
            return formatId(paymentId);
        },
        async loadPaymentHistory() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadPaymentHistory: true });
                const response = await this.$client.site(this.brandprofile).account(this.$route.params.accountId).payment.search();
                console.log(`loadPaymentHistory: response ${JSON.stringify(response)}`);
                if (Array.isArray(response?.list)) {
                    this.paymentList = response.list;
                } else {
                    this.paymentList = [];
                }
            } catch (err) {
                console.error('loadPaymentHistory: failed to load payment history', err);
                this.paymentList = [];
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadPaymentHistory: false });
                this.isDataReady = true;
            }
        },
    },
    mounted() {
        this.loadPaymentHistory();
    },
};
</script>
